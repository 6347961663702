(function ($) {
    const form = $('form#login'),
        inputs = form.find('input[type="number"]'),
        submit = form.find('input[type="submit"]');

    function isNumberPress(event) {
        //return (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode === 8 ? true : false);
		return true;
    }

    let valid = false;

    if(form.length) {
        inputs.on('keydown', function(e) {
            if(this.value > 0 && e.keyCode !== 8 || !isNumberPress(e)) {
                e.preventDefault();
            }
        });
        inputs.on('keyup', function(e) {
            let $this = $(this);
            if( Number.isInteger(parseInt(this.value)) ) {
                if($this.next().length) {
                    $this.next().focus();
                }
            }

            // build array of input values
            let arr = [];
            inputs.each(function(i,ele){
                if(ele.value.length) arr.push(parseInt(ele.value));
            })

            // handle valid attr for length of array
            if(arr.length == inputs.length) {
                $this.blur();
                submit.removeAttr('disabled').focus();
            } else {
                submit.attr('disabled', '')
            }
        });
    }

})(jQuery);