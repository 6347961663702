(function ($) {
    function init__login() {
        const 
            className = 'login',
            prefix = '.' + className,
            container = $(prefix);

        container.each(function(){
            let $this = $(this),
                img = $this.find(prefix + '__img'),
                logo = $this.find('.logo'),
                card = $this.find('.card'),
                tl = gsap.timeline({});

            gsap.set(img, {x: 100, opacity: 0});
            gsap.set([logo,card], {x: -100, opacity: 0});

            tl
                .to($this, 0, {opacity: 1,})
                .to([logo, card, img], 1, {x: 0, y: 0, opacity: 1, stagger: 0.4, ease: "Power3.easeOut"} )

            return tl;
        });
    }

    $(window).on('load', function() {
        init__login();
    });
    
    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            init__login();
        });
    }
    
})(jQuery);