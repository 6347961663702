(function ($) {
    // ==================================
    // controls out of barba wrapper
    // ==================================
    const header = {
        volume : $('.header__controls .videoControl__volume'),
        caption : $('.header__controls .videoControl__caption'),
    }

    header.volume.on('click', function(e){
        e.preventDefault();
        window.helpers.toggleVideoControl('volume');
    });

    header.caption.on('click', function(e){
        e.preventDefault();
        window.helpers.toggleVideoControl('caption');
    });

    // ==================================
    // video helpers to use globally
    // ==================================
    window.helpers.toggleVideoControl = function(key, boolean='') {
        if(boolean === '') {
            // check cookie 
            if(typeof Cookies.get('videoControl__'+key) === 'undefined') {
                // if no cookie check data attr 'state'
                boolean = !Number($('.header__controls .videoControl__'+key).data('state'));
            } else {
                // invert cookie value
                boolean = !Number(Cookies.get('videoControl__'+key));
            }
        }
        setVideoControl(key, Number(boolean));
    }

    function setVideoControl(key, boolean) {
        const
            header = {
                volume: $('.header__controls .videoControl__volume'),
                caption: $('.header__controls .videoControl__caption')
            },
            videos = $('video').not('[controls]').not('[muted]');

        Cookies.set('videoControl__'+key, boolean, { secure: location.protocol == 'https:'});
        header[key].attr('data-state', boolean);

        videos.each(function(){
            if(key == 'volume') {
                this.muted = !boolean;
            }
            if(key == 'caption' && this.textTracks.length) {
                this.textTracks[0].mode = !boolean ? 'hidden' : 'showing';
            }
        });
    }

    function initVideoControl() {
        // check cookies
        if(typeof Cookies.get('videoControl__volume') === 'undefined') {
            setVideoControl('volume', 1);
        } else {
            setVideoControl('volume', Number(Cookies.get('videoControl__volume')));
        }
        if(typeof Cookies.get('videoControl__caption') === 'undefined') {
            setVideoControl('caption', 0);
        } else {
            setVideoControl('caption', Number(Cookies.get('videoControl__caption')));
        }
    }

    initVideoControl();


    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            initVideoControl();
        });
    }
})(jQuery);