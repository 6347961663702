(function ($) {
    // for videos in grid format with controls enabled
    function videoStateChange() {
        const videos = $('video[controls]');

        videos.each(function(i, element) {
            $(element).on('play', function(e) {
                videos.not($(element)).each(function(i, ele) {
                    ele.pause();
                });
            });
        });
    }

    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            videoStateChange();
        });
    }   
})(jQuery);