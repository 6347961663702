(function ($) {
    $('.open-footer').magnificPopup({
        type:'inline',
        showCloseBtn: false,
        // for animation
        removalDelay: 300,
        mainClass: 'mfp-fade',
        callbacks: {
            close: function() {
                // remove hash
                history.replaceState(null, null, ' ');
            }
        }
    });
})(jQuery);