(function ($) {
    function tickerInit() {
        let ticker = $('.ticker');
        ticker.each(function(){
            $(this).carouselTicker({
                reverse: true,
            });

            $(window).on('resize', function() {
                $(this).carouselTicker().resizeTicker();
            });
        });
    }

    tickerInit();

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            tickerInit();
        });
    }   
})(jQuery);