(function ($) {
    // const 
    //     catArr = ['manu', 'agri', 'arch', 'busi', 'gove', 'heal', 'hosp', 'tech'];

    $.each(window.categories, function( index, value ) {
        let animID = value +'-right-transition';

        window.transition.init(animID);
        window.transition.generateSVGx($('#'+animID));

        let container = $('#'+animID),
        svg = container.find('svg'),
        paths = svg.find('path'),
        obj = {
            in : function(direction, callback) {
                let tl = gsap.timeline();
                gsap.set(container, {display: 'block'});
                gsap.set(container.children(), {xPercent: -100});
                gsap.set(paths[0], {morphSVG: paths[1]});
        
                tl
                    .to( container.children(), 0.7, { xPercent: 0, ease: 'none'})
                    .to( paths[0], 0.2, {morphSVG: paths[2] }, 0.5)
                    .add( callback )
                return tl;
            },
            out : function(direction, callback) {
                let tl = gsap.timeline();
                tl
                    .to( container.children(), 0.7, { xPercent: 100, ease: 'none'})
                    .to( paths[0], 0.2, {morphSVG: paths[3]}, 0)
                    .set( container, {display: 'none'})
                    .add( callback )
                return tl;
            }
        }

        // store animations
        container[0].animation = obj;
    }); 
})(jQuery);