(function ($) {
    
    function init__area() {
        const component = window.helpers.component('area');

        component.$.each(function(){
            const 
                $this = $(this),
                bg = $this.find(component.prefix + '__bg'),
                videoIn = $this.find(component.prefix+'__fly'),
                videoLoop = $this.find(component.prefix+'__loop'),
                svg = $this.find(component.prefix+'__overlay'),
                tabs = $this.find(component.prefix+'__tab'),
                tl = gsap.timeline({paused: true});

            tl.to($this, {duration: 0, opacity: 1, visibility: 'visible'});
            tl.add( anim__video(videoIn) );
            tl.addLabel('Fly Complete');
            tl.add( anim__video(videoLoop) );
            tl.add( anim__tabs(), 'Fly Complete' );
            tl.add( anim__svg(), 'Fly Complete' );
            tl.addLabel('End');


            tl.tweenTo('Fly Complete');
            videoIn.on('ended', function(){ 
                tl.play()
                $this.mousemove(function(event) {
                    window.animation.parallax(event, bg, false, 30);         
                });
            });

            function anim__video(ele) {
                let tl = gsap.timeline();

                gsap.set(ele, {opacity: 0});

                tl.to( ele, {opacity: 1});
                tl.add( function() { 
                    if (ele[0].localName == 'video') ele[0].play();
                }, 0);
                return tl;
            }

            function anim__tabs() {
                let tl = gsap.timeline();

                gsap.set(tabs[0], {xPercent: -100, yPercent: -50 });
                gsap.set(tabs[1], {xPercent: 100, yPercent: -50 });

                tl.to( tabs, {xPercent: 0, yPercent: -50, stagger: 0.5});

                return tl;
            }

            function anim__svg() {
                let tl = gsap.timeline();

                gsap.set(svg, {opacity: 0});

                tl.to( svg, {opacity: 1});

                return tl;
            }

        });
    }
    
    $(window).on('load', function() {
        init__area();
    });
    
    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            setTimeout(function() {
                init__area();
            }, 200);
        });
    }
})(jQuery);