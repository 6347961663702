(function ($) {
    let timeout = {
        set: undefined,
        time: 15,
        clear: function() {
            clearTimeout(timeout.set);
            timeout.set = undefined;
            // console.log('timeout clear');
            // console.log(timeout.set);
        },
        stop: function(video) {
            video.pause();
            gsap.to(video, { autoAlpha: 0});
        }
    }

    function init__timeout() {
        const component = window.helpers.component('room');

        component.$.each(function(i){
            // console.log('timeout init');
            const 
                $this = $(this),
                $bgVideo = $this.find(component.prefix+'__placeholder'),
                $videos = $this.find(component.prefix+'__unsure, ' +component.prefix+'__timeout'),
                $skip = $this.find(component.prefix+'__skip .btn');

            let index = 0;

            let isMobile = window.innerWidth < window.vars.breakpoint.lg;

            $bgVideo.on('play', function(e) {
                timeout.clear();
                setTimeOutVar();
                timeout.set();
            })

            $videos.each(function(){
                $(this).on('ended', function(){
                    stopVideo(this);
                });
            })

            $skip.on('click', function(){           
                stopVideo($videos[index > 0 ? index-1: $videos.length-1]);
            });

            // fix bug when going between tabs
            $(window).on('blur', function(){
                stopVideo($videos[index > 0 ? index-1: $videos.length-1]);
                timeout.clear();
            })

            function setTimeOutVar() {
                timeout.clear();
                timeout.set = function() {
                    setTimeout(function(){
                        if (typeof timeout.set === 'function' && !isMobile) {
                            console.log('start timeout function');
                            let videoClass = $videos[index].classList.value.replace(component.name + '__', '');
                            $this.addClass(videoClass);

                            window.helpers.playPromise($videos[index]);
                            gsap.to($videos[index], { delay: 0.2, autoAlpha: 1});

                            index = index < $videos.length -1 ? index + 1 : 0;
                        }
                    }, timeout.time*1000);
                }
            }

            function stopVideo(video) {
                timeout.stop(video);
                $this.removeClass('timeout');
                $this.removeClass('unsure');
                if (typeof timeout.set === 'function') {
                    timeout.set();
                }              
            }
           
        });
    }

    timeout.clear();
    init__timeout();

    if(window.barba !== undefined) {
        barba.hooks.before((data) => {
            timeout.clear();
        });

        barba.hooks.after((data) => {
            init__timeout();
        });
    }


})(jQuery);