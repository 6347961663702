(function ($) {
    
    function init__preload() {
        const 
            body = $('body'),
            preload = $('#preload'),
            asset = {
                domain : window.location.protocol + '//' + window.location.hostname,
                path : '/wp-content/themes/ja-stem/assets/video/',
            };

            if(window.location.href.indexOf('/html-build') >= 0) {
                asset.url = asset.domain + asset.path;
            } else {
                asset.url = 'https://ja-stem.s3-accelerate.amazonaws.com/video/';
            }

        if(body.hasClass('template--login')) {
            // preload onboarding intro video
            preloadFile(asset.url + 'onboard/intro.mp4');
        }

        if(body.hasClass('template--onboard')) {
            // preload home intro video
            preloadFile(asset.url + 'home/intro.mp4');
        }

        if(body.hasClass('template--foyer')) {
            // preload home intro video
            preloadFile([
                asset.url + 'scie/intro-01.mp4',
                asset.url + 'tech/intro-01.mp4',
                asset.url + 'engi/intro-01.mp4',
                asset.url + 'math/intro-01.mp4',
            ]);
        }
    }

    function preloadFile(path) {
        let preload = $('#preload');
        let exists = false;

        if(Array.isArray(path)) {
            $.each(path, function( index, value ) {
                appendPreload(value)
            });
        } else {
            appendPreload(path)
        }        
    }

    function appendPreload(str) {
        const preload = $('#preload');
        let exists = false;

        preload.children().each(function(){
            if(this.currentSrc == str) {
                exists = true;
                return;
            }
        });

        if(!exists) {
            let element = '';
            element += '<video preload>';
            element += '<source src="'+ str +'" type="video/mp4">'
            element += '</video>';

            preload.append($(element));
        }
    }

    init__preload();

    if(window.barba !== undefined) {
        barba.hooks.after((data) => {
            init__preload();
        });
    }
})(jQuery);