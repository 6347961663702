(function ($) {
    const $message = $('#playPromise__message');
    const $messageBtn = $('.playPromise__continue');

    window.helpers.playPromise = function(video) {
        let playPromise = video.play();
        

        if (playPromise !== undefined) {
            // set video to start
            video.currentTime = 0;
            
            playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
            })
            .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                toggleVideoPlayPromiseMessage(1);
            });
        }

        $messageBtn.on('click', function(e){
            e.preventDefault();
            let volume = Number($(this).data('volume'));

            window.helpers.toggleVideoControl('volume', volume);
            window.helpers.toggleVideoControl('caption', !volume);

            gsap.to($message, {autoAlpha: 0, y: -100});
            video.play()
        })
    }

    function toggleVideoPlayPromiseMessage(boolean=0) {
        if(boolean) {
            gsap.set($message, {autoAlpha: 0, y: -100, display: 'block'});
            gsap.to($message, {autoAlpha: 1, y: 0});
        } else {
            gsap.to($message, {autoAlpha: 0, y: -100});
            gsap.set($message, {display: 'none'});
        }
    }

    if(window.barba !== undefined) {
        barba.hooks.before((data) => {
            toggleVideoPlayPromiseMessage()
        });
    }

})(jQuery);