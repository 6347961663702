(function ($) {
    // sets the body class of the doc
    function getBodyClass(data) {
        const 
            // set the body classes
            nextHtml = data.next.html,
            // who knows what the regex does but works
            response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml),
            bodyClasses = $(response).filter('notbody').attr('class');

            return bodyClasses;
    }

    function setBodyClass(data) {
        $("body").attr("class", getBodyClass(data));
    }
    
    function handleWindowHash() {
        const 
            hash = window.location.hash, // detect if hash exists
            y = (hash !== '' && $(hash).length ? $(hash).offset().top : 0);
        
        window.scrollTo(0, y);
    }

    // const categoryArr = ['manu', 'agri', 'arch', 'busi', 'gove', 'heal', 'hosp', 'tech'];
    const transitionArr = [
        {
            name: 'default-transition',
            leave(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();

                ele.animation.in( function() { done() } );
            },
            enter(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();
                
                done();

                setTimeout(function(){ ele.animation.out() }, 200);
            }
        },
        {
            name: 'up-transition',
            from: { namespace: $.map( window.categories, function( val, i ) { return 'area-' +val }) },
            to: { namespace: $.map( window.categories, function( val, i ) { return ['paths-' +val, 'resources-' +val,] }) },
            leave(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();

                ele.animation.in( function() { done() } );
            },
            enter(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();
                
                done();

                setTimeout(function(){ ele.animation.out() }, 200);
            },
        },
        {
            name: 'down-transition',
            from: { namespace: $.map( window.categories, function( val, i ) { return ['paths-' +val, 'resources-' +val,] })},
            to: { namespace: $.map( window.categories, function( val, i ) { return 'area-' +val }) },
            leave(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();

                ele.animation.in( function() { done() } );
            },
            enter(data) {
                let ele = document.getElementById(this.name),
                    done = this.async();
                
                done();

                setTimeout(function(){ ele.animation.out() }, 10);
            },
        }
        
    ];

    $.each(window.categories, function(i, val) {
        let obj = {
            name: val+'-transition',
            to: { namespace: ['area-'+val,]},
            leave(data) {
                let direction = (data.trigger.text == 'Prev Zone' ? false : true);
                let name = this.name.replace('-transition', (direction ? '-right-transition' : '-left-transition'));
                var ele = document.getElementById(name);
        
                var done = this.async();
            
                ele.animation.in(direction, 
                    function() { done(); }
                );
            },
            enter(data) {
                let direction = (data.trigger.text == 'Prev Zone' ? false : true);
                let name = this.name.replace('-transition', (direction ? '-right-transition' : '-left-transition'));
                var ele = document.getElementById(name);

                var done = this.async();
                
                done();

                setTimeout(function(){ 
                    ele.animation.out(direction);
                }, 400);
            }
        };
        transitionArr.push(obj);
    });




    if(window.barba !== undefined) {

        barba.init({
            debug: (!window.productionMode ? true : false),
            cacheIgnore: ['/', '/html-build/'],
            timeout: 5000,
            transitions: transitionArr
        });

        barba.hooks.beforeEnter((data) => {
            setBodyClass(data);
            handleWindowHash();
        });

    }
    
})(jQuery);