(function ($) {
    function init__onboard() {
        const component = window.helpers.component('onboard');

        component.$.each(function(){
            // ================
            // vars 
            // ================
            const
                $this = $(this),
                query = {},
                tl = gsap.timeline(),
                $skip = $this.find('.skip'),
                dataURL = $this.data('url'),
                video = {
                    all: $this.find(component.prefix +'__bg video'),
                    in: $this.find(component.prefix +'__in'),
                    out: $this.find(component.prefix +'__out'),
                    question: $this.find('video'+component.prefix +'__question'),
                },
                content = {
                    question: $this.find('div'+component.prefix +'__question'),
                };
                content.btn = content.question.find('.btn');

            let timeNow;
            
            if(video.in.length) {

                tl.set([video.in, $skip], {display: 'block', autoAlpha: 0});
                tl.to(video.in, {autoAlpha: 1});
                tl.to($skip, {autoAlpha: 1});
                tl.add( window.helpers.playPromise(video.in[0]) );
                tl;

            } else {
                init__questions(0);
            }

            content.btn.on('click', function(){
                let parent = $(this).closest('.onboard__question');
                let index = parent.prevAll().length;
                let key = parent.data('key');
                let value = this.value;
                query[key] = value;
                    
                init__questions(index + 1);
            });

            video.in.on('ended', function(){
                gsap.to(video.in, {autoAlpha: 0} );
                init__questions(0);
            });

            // start timer
            $(video.question[0]).on('play', function(){
                setTimeout(function(){ window.helpers.startOnboardTimer(30) }, 5000);
            });

            video.out.on('ended', function(){
                goHome()
            });

            $skip.on('click', function(){
                goHome()
            });

            function init__questions(i) {
                let tl = gsap.timeline();
                let thisVid = video.question[i];
                let thisCard = content.question[i];
                let isArr = [thisVid, thisCard];
                let notArr = [video.question.not(thisVid), content.question.not(thisVid)]

                window.helpers.stopVideos();

                tl.to(notArr, {autoAlpha: 0}, 0);
                tl.set(notArr, {display: 'none'});

                if(i < content.question.length) {
                    tl.set(isArr, {display: 'block', autoAlpha: 0});
                    tl.to(isArr, {autoAlpha: 1});
                    tl.add( window.helpers.playPromise(thisVid) );
                } else {
                    // play outro video
                    tl.set(video.out, {display: 'block', autoAlpha: 0});
                    tl.to(video.out, {autoAlpha: 1});
                    tl.add( window.helpers.playPromise(video.out[0]));
                }

                return tl;
            }

            function goHome() {
                let arr = [];
                let i = 0;
                $.map( query, function( value, index ) {
                    arr.push(index+'='+value);
                });
                
                let url = dataURL + (arr.length ? '?' : '') + arr.toString().replaceAll(',', '&');

                // barba.go(url);
                window.location.href = url;
            }
        });
    }

   

    init__onboard();

    if(window.barba !== undefined) {
        barba.hooks.after((data) => {
            init__onboard();
        });
    }
})(jQuery);