(function ($) {
    const 
        animID = 'down-transition',
        body = $('body'),
        barbaWrap = $('[data-barba="wrapper"]'),
        main = $('main');

    window.transition.init(animID);
    window.transition.generateSVGy($('#'+animID));

    let container = $('#'+animID),
        svg = container.find('svg'),
        paths = svg.find('path'),
        obj = {
        in : function(callback) {
            let tl = gsap.timeline();
                gsap.set(container, {display: 'block'});
                gsap.set(container.children(), {yPercent: -100});
                gsap.set(paths[0], {morphSVG: paths[3]});
        
                tl
                    .to( container.children(), 0.7, { yPercent: 0, ease: 'none'})
                    .to( paths[0], 0.2, {morphSVG: paths[2] }, 0.5)
                    .add( callback )
                return tl;
        },
        out : function(callback) {
            let tl = gsap.timeline();
                tl
                    .to( container.children(), 0.7, { yPercent: 100, ease: 'none'})
                    .to( paths[0], 0.2, {morphSVG: paths[1]}, 0)
                    .set( container, {display: 'none'})
                    .add( callback )
                return tl;
        }
    }

    // store animations
    container[0].animation = obj;
    
})(jQuery);