(function ($) {

    function autocompleteInit(searchObj) {
        const component = window.helpers.component('autocomplete');

        function enable_disableNext(value, $btn) {
            let inArr = false;
            $.each(searchObj, function( i, val ) {
                if (val.title == value) {
                    inArr = true;
                }
            });

            inArr ? $btn.removeAttr('disabled') : $btn.attr('disabled', '');
        }

        function enable_disableCheckbox(value, $input) {
            let inArr = false;
            $.each(searchObj, function( i, val ) {
                if (val.title == value) {
                    inArr = true;
                }
            });

            inArr ? $input.attr('disabled', '') : $input.removeAttr('disabled');
        }

        component.$.each(function(i) {
            const 
                $this = $(this),
                input = $this.find(component.prefix+'__input'),
                drop = $this.find(component.prefix+'__drop'),
                dropNote = $this.find(component.prefix+'__drop-note'),
                dropList = $this.find(component.prefix+'__drop-list'),
                unsureInput = $this.closest('.onboard__carsousel-item').find('#unsure'),
                nextBtn = $this.closest('.onboard__carsousel-item').find('.next');

            let active = false;

            input.on('focusout', function(e) {
                let value = this.value.toLowerCase();
                setTimeout(function(){ 
                    drop.removeClass('active');
                    active = false;
                }, 500);
            });

            input.on('keyup focus', function(e) {
                e.preventDefault();
                let value = this.value.toLowerCase();

                // ====================================================
                // creates and removes list 
                // this will contain the items
                if(value.length > 2 && !active) {
                    drop.addClass('active');
                    active = true;
                }

                if(value == '' && active) {
                    // remove div
                    drop.removeClass('active');
                    active = false;
                }
                // creates and removes list 
                // this will contain the items
                // ====================================================

                if(active) {
                    let resultIndex = 0;
                    dropNote.empty();
                    dropList.empty();

                    $.each(searchObj, function( i, val ) {
                        if(val.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            dropList.append('<li data-value="' + val.title + '">' + val.title + '</li>');
                            resultIndex = resultIndex + 1;
                        }
                    });

                    // update drop note
                    dropNote.append((resultIndex == 0 ? 'no items available' : resultIndex+ ' items found'));
                }
            });

            dropList.on('click', 'li', function(e) {
                let val = $(this).data('value');
                input.val(val);
                drop.removeClass('active');

                active = false;
            });
        });        
    }

    
    
    autocompleteInit(window.jobs);

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            autocompleteInit(window.jobs);
        });
    }

})(jQuery);