(function ($) {
    window.helpers.stopVideos = function() {
        $('video').each(function(){
            this.pause();
        });
    }

    if(window.barba !== undefined) {
        barba.hooks.before((data) => {
            window.helpers.stopVideos()
        });
    }
})(jQuery);


