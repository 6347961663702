(function ($) {
    // ===================================
    // sets a cookie for each cluster
    // ===================================

    function clusterCookie() {
        let body = $('body');
        let classArr = body.length ? body[0].classList.value.split(' ') : [];
        let cluster = 'none';
        let replace = 'category--'

        $.each(classArr, function(index, str) {
            if(str.startsWith(replace)) {
                cluster = str.replace(replace, '');
            }
        });

        Cookies.set('current_cluster', cluster, { secure: location.protocol == 'https:'});
        // console.log(cluster);
    }

    clusterCookie();

    if(window.barba !== undefined) {
        barba.hooks.after((data) => {
            clusterCookie();
        });
    }

})(jQuery);