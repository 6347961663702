(function ($) {
    const 
        animID = 'default-transition',
        body = $('body'),
        barbaWrap = $('[data-barba="wrapper"]'),
        main = $('main');

    window.transition.init(animID);

    let container = $('#'+animID),
        obj = {
        in : function(callback) {
            let tl = gsap.timeline();

            // gsap.set(container, {display: 'block'});
            // gsap.set(container.children(), {xPercent: -100});
    
            tl
                .to( barbaWrap, { opacity: 0, })
                .add( callback )
            return tl;
        },
        out : function(callback) {
            let tl = gsap.timeline();
    
            tl
                // .to( barbaWrap, 0, {  })
                .to( barbaWrap, 1, { opacity: 1, })
                // .to( container.children(), 1, { xPercent: 100 })
                // .set( container, {display: 'none'})
                .add( callback )
            return tl;
        }
    }

    // store animations
    container[0].animation = obj;

    window.transition.generateDivs(container, 1);

    
})(jQuery);