(function ($) {

    function initFilters() {
        const component = window.helpers.component('filter');

        component.$.each(function() {
            const 
                $this = $(this),
                section = $(this).closest('section'),
                nav = $(this).find(component.prefix + '__nav'),
                navItem = nav.find('[type="radio"]'),
                content = section.find(component.prefix + '__content'),
                contentItem = content.find('[data-filter]'),
                masonry = section.find('.masonry');

            let 
                queryKey = navItem[0].name, // get the name of the radio group
                queryVal = navItem[0].id.replace(queryKey+'__', ''); // remove key from id
                
            filterSet(getFilterIndex());

            // ===========================
            // change
            // ===========================
            navItem.on('change', function(e) {
                const id = $(this).attr('id');
                let index = 0;

                navItem.each(function(i) {
                    if (id === $(this).attr('id')) index = i;
                });

                filterSet(index);            
            });

            function getFilterIndex() {
                let index = 0;
                if (window.location.search.length) { // if url has query string
                    // breakdown the query string to array and return value if match
                    const 
                        array = window.location.search.slice(1).split('&'),
                        val = jQuery.map( array, function( n, i ) {
                            let arr = n.split('='), key = arr[0], val = arr[1];
                            if(key == queryKey) return val;
                        });

                        // check each navitem against value
                        navItem.each(function(i) {
                            if($(this).attr('id') == queryKey+'__'+val) {
                                queryVal = val;
                                index = i;
                            }
                        });
                }
                
                return index;
            }

            function filterSet(i) {
                queryVal = navItem[i].id.replace(queryKey+'__', ''); // set queryVal again by i

                $(navItem[i]).prop('checked', true);
                // handles first id is all
                if(i == 0) {
                    content.find('[data-filter]').show();
                } else {
                    content.find('[data-filter]').hide();
                    content.find('[data-filter="' + queryVal + '"]').show();
                }

                if(masonry.length) {
                    if(i == 0) {

                    } else {

                    }
                    // (i == 0 ? '[data-filter]' : '[data-filter="' + queryVal + '"]');
                    masonry.masonry({columnWidth: (i == 0 ? '[data-filter]' : '[data-filter="' + queryVal + '"]')});
                }
            }            
        });
    }

    initFilters();

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            initFilters();
        });
    }
})(jQuery);