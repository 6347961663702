(function ($) {
    const 
        menu = $('.nav__menu'),
        hasChild = menu.find('.has-child'),
        childHref = hasChild.children('a');

        // remove open by default on mobile
        if (window.innerWidth < 1050 ) {
            hasChild.removeClass('active');
        }

        // set animation for left menu
        hasChild.each(function(i, ele) {
            const 
                $this = $(ele),
                tl = gsap.timeline({paused: true}),
                ul = $this.find('ul'),
                id = $this.data('id'),
                content = $('#'+id);

            gsap.set([ul, content], { opacity: 0, display: 'none', height: 0});

            tl.set([ul, content], { display: 'block',})
            tl.to([ul, content], {height: 'auto', ease: 'power2.inOut', opacity: 1}, 0);

            if($this.hasClass('active')) {
                tl.seek(tl.duration(), false);
            }

            ele.animation = tl;
        });

        childHref.on('click', function(e) {
            e.preventDefault();
            const 
                $this = $(this),
                parent = $this.closest('.has-child');

            parent.toggleClass('active');
            parent.siblings('.has-child').removeClass('active');

            if(parent.hasClass('active')) {
                parent.siblings('.has-child').each(function() {
                    this.animation.reverse();
                });
                parent[0].animation.play();
            } else {
                parent[0].animation.reverse();
            }
        });

        // barba hook
        if(window.barba !== undefined) {
            barba.hooks.enter((data) => {
                checkNavSelected()
            });
        }

        function checkNavSelected() {
            if(menu.length) {
                const 
                    li = menu.find('li'),
                    a = li.children('a'),
                    path = window.location.pathname;

                // console.log(window.location.pathname)

                // remove selected class
                li.removeClass('selected');

                // add selected class
                li.each(function(i) {
                    const href = $(a[i]).attr('href');
                    if (href == path) $(this).addClass('selected')
                });
            }
        }
})(jQuery);