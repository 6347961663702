(function ($) {

    window.animation = {};

    window.animation['parallax'] = function(e, target, invert=false, movement=50) {
        let move = movement * 0.001,
            moveX = ((e.pageX - window.innerWidth/2) * move).toFixed(),
            moveY = ((e.pageY - window.innerWidth/2) * move).toFixed();
    
        gsap.to(target, {
            duration: 1.5,
            x: (!invert ? moveX * -1 : moveX),
            y: (!invert ? moveY * -1 : moveY),
        });
    }

})(jQuery);