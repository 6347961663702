(function ($) {
    function init__program(){
        const component = window.helpers.component('program');

        component.$.each(function(){
            const 
                $this = $(this),
                $video = $this.find('video'),
                $skip = $this.find('.skip'),
                tl = gsap.timeline(),
                query = window.location.search.replace('?', ''),
                queryVal = query.split('=')[1],
                href = queryVal !== undefined ? queryVal : '/';

                tl.to($this, {autoAlpha: 1});
                tl.add( function(){ window.helpers.playPromise($video[0]) });
                tl.to($skip, 0, { display: 'block', autoAlpha: 0 });
                tl.to($skip, { autoAlpha: 1}, '+=1');

            $video.on('ended', function(){
                window.location.href = href
            });

            $skip.on('click', 'button', function(){
                window.location.href = href;
            });
        });
    }

    init__program();

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            init__program();
        });
    }
})(jQuery);