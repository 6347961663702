(function ($) { 
    function masonryInit() {
        const masonry = $('.masonry');

        masonry.each(function(){
            let $this = $(this);
            $this.masonry({
                // percentPosition: true,
                // columnWidth: $this.children(),
            });
        });
    }
    $(window).on('load', function () {
        masonryInit();
    });

    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            masonryInit();
        });
    }
})(jQuery);