import './_helpers.js';
import './_vars.js';

import './components/_autocomplete.v2.js';
import './components/_carousel.js';
import './components/_cluster.js';
import './components/_filter.js';
import './components/_footer.js';
import './components/_login.js';
import './components/_masonry-grid.js';
import './components/_menu.js';
import './components/_nav.v2.js';
import './components/_onboard.v2.js';
import './components/_playPromise.js';
import './components/_prize.js';
import './components/_program.js';
import './components/_tabs.js';
import './components/_ticker.js';
import './components/_timer.js';

import './components/video/_controls.js';
import './components/video/_mute.js';
import './components/video/_play-promise.js';
import './components/video/_preload.js';
import './components/video/_state-change.js';
import './components/video/_stop-video.js';

import './animation/_helpers.js';
import './animation/room/init.js';
import './animation/room/timeout.js';
import './animation/area.js';
import './animation/help.js';
import './animation/login.js';
import './animation/onboard.v2.js';
import './animation/vip.js';

import './transitions/_helpers.js';
import './transitions/barba.js';
import './transitions/default.js';
// import './transitions/categories.js';
import './transitions/category-right.js';
import './transitions/category-left.js';
import './transitions/down.js';
import './transitions/up.js';
