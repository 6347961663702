(function ($) {
    var clickEle = $('.open-prize');
    var modelEle = $('#prize');

    clickEle.on('click', function(e){
        e.preventDefault;
        prizeModel();
    });

    $(document).ready(function() {
        if (window.location.hash == '#prize') prizeModel();
    });

    function prizeModel() {

        $.magnificPopup.open({
            showCloseBtn: false,
            removalDelay: 300,
            mainClass: 'mfp-fade',
            items: {
                src: modelEle, // can be a HTML string, jQuery object, or CSS selector
                type: 'inline',
            },
            callbacks: {
                open: function() {
                    $(this.content).find('#prize__begin').prop('checked', false);
                },
                close: function() {
                    // remove hash
                    history.replaceState(null, null, ' ');
                }
            }
        });
        
    }
})(jQuery);