(function ($) {

    function tabsInit() {
        const component = window.helpers.component('tabs');

        component.$.each(function() {
            const 
                $this = $(this),
                nav = $this.find(component.prefix + '__nav'),
                navItem = nav.children(),
                content = $this.find(component.prefix + '__content'),
                contentItem = content.children(),
                hasItems = contentItem.length && navItem.length;

            let tabAnimating = false;

            // add classes 
            $this.addClass('init');
            $([navItem[0], contentItem[0]]).addClass('active');

            // set animation stuff
            gsap.set(contentItem, { css: { opacity: 0, visibility: 'hidden'}});
            gsap.set(contentItem[0], { css: { opacity: 1, visibility: 'visible'}});

            tabsSet(0)

            // // adjusts content height on resize
            // $( window ).resize(function() {
            //     gsap.set(content , { height: content.find('.active').outerHeight() });
            // });

            // ===========================
            // set tab
            // ===========================
            function tabsSet(i) {
                
                var prev = $(contentItem[i]).siblings('.active');
                var animOut = (prev.length ? prev[0].animation : false);
                var animIn = (contentItem[i] !== undefined ? contentItem[i].animation : false);
    
                if(animOut) animOut.seek('In').tweenTo('Out');
                if(animIn) animIn.seek('Ready').tweenTo('In');

                // fix if nav is removed
                let eleArr = [];

                if(contentItem.length && contentItem[i] !== 'undefined') {
                    eleArr.push(contentItem[i]);
                }
                if(navItem.length && navItem[i] !== 'undefined') {
                    eleArr.push(navItem[i]);
                }
    
                $(eleArr).addClass('active').siblings().removeClass('active');
            }

            // ===========================
            // animation
            // ===========================
            contentItem.each(function(i, ele) {
                var tl = gsap.timeline({paused: true, reversed: true});

                tl.to(ele, { duration: 0, opacity:0, visibility: 'visible'}, 0)
                tl.addLabel('Ready')
                tl.to(ele, { opacity: 1}, 'Ready')
                // tl.to(content, { duration: 0, height: $(ele).outerHeight() }, 'Ready')
                tl.addLabel('In')
                tl.to(ele, { opacity: 0}, 'In')
                tl.to(ele, { duration: 0, visibility: 'visible'}, 'In')
                tl.addLabel('Out')

                this.animation = tl;
            });

            // ===========================
            // click
            // ===========================
            navItem.on('click', 'a', function(e) {
                e.preventDefault();
                var $this = $(this).parent();

                if(!$this.hasClass('active') && !tabAnimating) {
                    var i = $this.prevAll().length;
                    tabsSet(i)

                    // timeout to stop fuckers trying to break
                    tabAnimating = true;
                    setTimeout(function(){ tabAnimating = false; }, 600);
                }
            });
        });
    }

    tabsInit();

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            // setTimeout(function() {
                tabsInit();
            // }, 200);
        });
    }
    
})(jQuery);