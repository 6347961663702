(function ($) {
    function init__room() {
        const component = window.helpers.component('room');

        component.$.each(function(i){
            // console.log('init room')
            if(i == component.$.length-1) {
            const
                $this = $(this),
                // elements
                $videoIn = $this.find(component.prefix+'__intro'),
                $videoIn2 = $this.find(component.prefix+'__intro2'),
                $videobg = $this.find(component.prefix+'__placeholder'),
                $videobgImg = $this.find(component.prefix+'__placeholderImg'),
                $content = $this.find(component.prefix+'__content'),
                $siblings = $this.find(component.prefix+'__siblings'),
                $contentAll = $.merge($content, $siblings.children()),
                $skip = $this.find(component.prefix+'__skip .btn'),

                roomKey = $this.attr('data-'+component.name),
                // conditions
                isCluster = Cookies.get('current_cluster') == roomKey,
                isViewed = Cookies.get('room_visited__'+roomKey) !== undefined,
                isMobile = window.innerWidth < window.vars.breakpoint.lg,
                // timeline 
                tl = gsap.timeline({ paused: true });


                if(isMobile) {
                    tl.to(component.$, {autoAlpha: 1});
                } else if(!isViewed) {
                    tl.to(component.$, {autoAlpha: 1})
                    tl.add( anim__introVideo($videoIn) , '+=0')
                } else if ($videoIn2.length && !isCluster) {
                    tl.to(component.$, {autoAlpha: 1});
                    tl.add( anim__introVideo($videoIn2) , '+=0')
                } else {
                    tl.to(component.$, {autoAlpha: 1});
                    tl.add( anim__bgVideo($videobg) , '+=0');
                    tl.add( anim__content($contentAll) );
                }

                

                tl.play()

                $videoIn.on('ended', function(){
                    Cookies.set('room_visited__'+roomKey, 1, { secure: location.protocol == 'https:'});
                    anim__videoOut(this, $videobg, $contentAll)
                    $this.removeClass('intro')
                });

                $videoIn2.on('ended', function(){
                    anim__videoOut(this, $videobg, $contentAll)
                    $this.removeClass('intro')
                });

                $videoIn.on('play', function(){
                    setTimeout(function(){}, 3000);
                });

                $skip.on('click', function(){
                    if(!$videoIn[0].paused) {
                        $videoIn[0].pause()
                        Cookies.set('room_visited__'+roomKey, 1, { secure: location.protocol == 'https:'});
                        anim__videoOut(this, $videobg, $contentAll)
                        $this.removeClass('intro')
                    }

                    if($videoIn2.length && !$videoIn2[0].paused) {
                        $videoIn2[0].pause()
                        anim__videoOut(this, $videobg, $contentAll)
                        $this.removeClass('intro')
                    }
                });
            }
        });

        

        function anim__introVideo($video) {
            if($video.length) {
                let tl = gsap.timeline();
                gsap.set($video, {autoAlpha: 0});        

                tl.to($video, 0, {autoAlpha: 1,});
                tl.add( function() {
                    window.helpers.playPromise($video[0]);
                });
                tl.add( ()=> { $video.closest('section').addClass('intro')}, 2)
                return tl;
            }
        }

        function anim__videoOut(video, $bg, $contentArr) {
            let tl = gsap.timeline();
            tl.to(video, {autoAlpha: 0});
            tl.add( anim__bgVideo($bg) ,'-=0.5');
            tl.add( anim__content($contentArr));
            
        }
        function anim__bgVideo($video) {
            let tl = gsap.timeline();
            if($video.length) {
                tl.to($video, {autoAlpha: 1});
                tl.add( window.helpers.playPromise($video[0]) ,'-=0.5');
            }
            return tl;
        }
        function anim__content($arr) {
            let tl = gsap.timeline();
            $arr.each(function(){
                tl.to(this, {autoAlpha: 1, x: 0});
            })
            return tl;
        }
    }

    if(window.barba !== undefined) {
        barba.hooks.beforeEnter((data) => {
            let arr = ['area-scie', 'area-tech', 'area-engi', 'area-math', 'foyer'];
            let thisPage = false;
            $.each(arr, function( key, value ) {
                if(data.next.namespace == value) {
                    thisPage = true;
                    return false;
                }
            });

            if(thisPage) {
                init__room();
            }
        });
    } else {
        init__room();
    }

})(jQuery);