(function ($) {

    window.helpers.startOnboardTimer = function(time) {
        const component = window.helpers.component('timer');
        const parts = {
            seconds: component.$.find('.seconds'),
            milliseconds: component.$.find('.milliseconds'),
        };

        let interval;
        let milliseconds = time*100;

        function startTimer() {
            interval = setInterval(function(){
                if(milliseconds > 0) {
                    updateTimer(milliseconds)
                    warningTimer();
                 } else {
                    stopTimer()
                 }
            }, 10);
        }

        function updateTimer() {
            milliseconds = milliseconds - 1
            let padded = String(milliseconds).padStart(4, '0');
            let arr = padded.match(/.{1,2}/g);

            // update timer
            parts.seconds.text(arr[0])
            parts.milliseconds.text(arr[1])
        }

        function warningTimer() {
            if(milliseconds < time*100 / 2 && !component.$.hasClass('warning'))
            component.$.addClass('warning');
        }

        function stopTimer(){
            clearInterval(interval);
            component.$.removeClass('warning');
            component.$.addClass('expired');
            
        }

        startTimer();

    }
    
})(jQuery);


