(function ($) {
    function cardCarouselInit() {
        let carousel = $('.card__carousel').not('.slick-initialized');

        carousel.each(function(){
            let $this = $(this);
            
            $this.slick({
                arrows: false,
                dots: true,
                infinite: false,
            });
        });   
    }        

    function onboardCarouselInit() {
        let carousel = $('.onboard__carsousel').not('.slick-initialized');

        carousel.each(function(){
            let $this = $(this),
                block = $this.closest('.onboard'),
                dots = block.find('.onboard__progress li'),
                next = carousel.find('.next'),
                prev = carousel.find('.prev');

            $this.on('init', function(event, slick) {
                $(dots[0]).addClass('active');
            });

            $this.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                if(slick.$slider.hasClass('onboard__carsousel')) {
                    dots.removeClass('active');
                    $(dots[nextSlide]).addClass('active');

                    // for chosen cluster
                    let input = $(slick.$slides[currentSlide]).find('.autocomplete__input');
                    let getCatID = function () {

                    }
                    if(input.length && window.jobs !== undefined ) {
                        let categoryID = 'unsure';
                        $.each(window.jobs, function( i, val ){
                            if(val.title.toLowerCase() == input[0].value.toLowerCase()) categoryID = val.catID;
                        });
                            $('.onboard__result').removeClass('selected');
                            $('.onboard__result[data-cat="'+categoryID+'"]').addClass('selected');
                            Cookies.set('chosen_cluster', categoryID, { secure: location.protocol == 'https:'});

                        // modify the href
                        // need: https://jaci.them-sparks.co.uk/url-link/redirect/?jobtitle=[field]&found=[0%7C1]');
                        let nextURL = window.location.origin + '/url-link/redirect/?';
                        nextURL += 'jobtitle=' + window.helpers.encodeHTML(input[0].value);
                        nextURL += '&found='+ (categoryID === 'unsure' ? '0' : '1');

                        $(slick.$slides[nextSlide]).find('.btn').attr('href', encodeURI(nextURL));
                    }

                    // set max height
                    let maxHeight = $(slick.$slides[currentSlide]).outerHeight();
                    $.each(slick.$slides, function(i, ele) {
                        if ($(ele).outerHeight() > maxHeight) {
                            maxHeight = $(ele).outerHeight();
                        } 
                    });
                    $(slick.$slideTrack).height(Math.round(maxHeight));                    
                }
                    
            });

            $this.on('afterChange', function(event, slick, currentSlide){
            });

            $this.slick({
                draggable: false,
                arrows: false,
                dots: false,
                speed: 700,
                adaptiveHeight: true,
            });            

            next.on('click', function(e){
                e.preventDefault();
                $this.slick('slickNext');
            });

            prev.on('click', function(e){
                e.preventDefault();
                $this.slick('slickPrev');
            });
        });
    }

    cardCarouselInit();
    onboardCarouselInit();
    

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            cardCarouselInit();
            onboardCarouselInit();
        });
    }     

})(jQuery);