(function ($) {
    // ==========================
    // detect local enviroments
    // ==========================
    const LOCAL_DOMAINS = ['localhost', 'ignosxl08.local'];
    window.productionMode = (LOCAL_DOMAINS.indexOf(window.location.hostname) !== -1 ? false : true);


    window.helpers = {};

    // =============================================
    // add this class to use History back() Method
    // =============================================
    window.helpers['goBack'] = function() {
        // clear the href
        $('.js_go-back').attr('href', '');
        if(window.barba !== undefined && barba.history.h.length > 1) {
            // update the href on the class
            $('.js_go-back').attr('href', 'javascript:history.back()');
        }
        $('.js_go-back').on('click', function(e){
            if($(this).attr('href') == '') {
                e.preventDefault();
                window.history.back();
            }
        });
    }

    window.helpers['component'] = function(classname) {
        let obj = {
            name: classname,
            prefix: '.'+classname,
        }
        if (window.jQuery) obj['$'] = $(obj.prefix);
        return obj;
    }

    window.helpers['encodeHTML'] = function(str) {
        const code = {
            ' ' : '&nbsp;',
            '¢' : '&cent;',
            '£' : '&pound;',
            '¥' : '&yen;',
            '€' : '&euro;', 
            '©' : '&copy;',
            '®' : '&reg;',
            '<' : '&lt;', 
            '>' : '&gt;',  
            '"' : '&quot;', 
            '&' : '^',
            '\'' : '&apos;'
        };
        return str.replace(/[\u00A0-\u9999<>\&''""]/gm, (i)=>code[i]);
    }

    // run helper functions
    window.helpers.goBack();
    
    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            window.helpers.goBack();
        });
    }



    // category clusters
    window.categories = ['scie','tech', 'engi', 'math'];

})(jQuery);