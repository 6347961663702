(function ($) {
    window.transition = {};

    window.transition.init = function (id, markup='') {
        // add html at end of body tag
        if($('#'+id).length == 0) $('body').append('<div class="transition" id="'+id+'">'+markup+'</div>');
        // add options to select element
        $('.transition__select').append('<option value="'+id+'">' + id.replace(/-/g, ' ') + '</option>');
        barba.hooks.enter((data) => {
            $('.transition__select').append('<option value="'+id+'">' + id.replace(/-/g, ' ') + '</option>');
        });
    }
    window.transition.generateDivs = function($ele, num) {
        let i = 0;
        while (i < num) {
            $ele.append('<div></div>');
            i++;
        }
    }
    window.transition.generateSVGx = function($ele) {
        $ele.append(
            '<svg viewBox="0 0 160 90" preserveAspectRatio="xMidYMid slice">' +
            '<path d="M0,0h160c0,0-16,11.9-16,45s16,45,16,45H0c0,0,0-11.9,0-45S0,0,0,0z"/>' +
            '<path d="M0,0h160c0,0-16,11.9-16,45s16,45,16,45H0c0,0,0-11.9,0-45S0,0,0,0z"/>' +
            '<path d="M0,0h160c0,0,0,11.9,0,45s0,45,0,45H0c0,0,0-11.9,0-45S0,0,0,0z"/>' +
            '<path d="M0,0h160c0,0,0,11.9,0,45s0,45,0,45H0c0,0,15-11.9,15-45S0,0,0,0z"/>' +
            '</svg>'
        );
    }
    window.transition.generateSVGy = function($ele) {
        $ele.append(
            '<svg viewBox="0 0 160 90" preserveAspectRatio="xMidYMid slice">' +
            '<path d="M160,90c0,0-25.4,0-80,0S0,90,0,90V0c0,0,29.6,20,80,20s80-20,80-20V90z"/>' +
            '<path d="M160,90c0,0-25.4,0-80,0S0,90,0,90V0c0,0,29.6,20,80,20s80-20,80-20V90z"/>' +
            '<path d="M160,90c0,0-25.4,0-80,0S0,90,0,90V0c0,0,29.6,0,80,0s80,0,80,0V90z"/>' +
            '<path d="M160,90c0,0-25.4-20-80-20S0,90,0,90V0c0,0,29.6,0,80,0s80,0,80,0V90z"/>' +
            '</svg>'
        );
    }
    
})(jQuery);