(function ($) {
    const 
        checkbox = $('#menu_toggle'),
        toggle = $('.menu-toggle'),
        header = $('.header'),
        nav = $('.nav'),
        content = $('.nav__container');

    var tl = gsap.timeline({
        paused:true,
        onComplete: function(){ 
            $('body').removeClass('nav--active')
        },
    });

    gsap.set('html', {overflow: 'auto'});

    tl
        .addLabel('Start')
        .to('html', 0, { overflow: 'hidden' })
        .add( anim__toggleFlip(toggle), 0.3 )
        .add( anim__toggleGrow(nav).duration(1.5), 0 )
        .add( anim__hideBack(header), '-=1' )
        .add( anim__showContainer(nav), '-=0.5')
        .addLabel('Open')
        // close the menu
        .add( anim__toggleFlip(toggle, true), 'Open')
        .to(nav, { opacity: 0, delay: 0.3 }, 'Open')
        .add( anim__showBack(header), 'Open+=0.2' )
        .to(nav, 0, { display: 'none'} ) 
        .to('html', 0, { overflow: 'auto' })
        .addLabel('Close')
    
    if(checkbox.length) checkbox[0].animation = tl;

    checkbox.on('change',function(){
        var checked = $(this).prop('checked');
        if(checked) {
            this.animation.tweenFromTo('Start', 'Open')
            window.helpers.muteVideos();
        } else {
            this.animation.tweenFromTo('Open', 'Close') 
            window.helpers.muteVideos(false);
        }
    });

    function anim__toggleFlip(ele, reverse=false) {
        var tl = gsap.timeline({
            onStart: function(){
                setTimeout(function() {
                    if (reverse) $('body').removeClass('nav--active')
                }, 300);
            },
        }),
            flipInner = ele.find('.menu-toggle__inner');

        if(!reverse)  {
            gsap.set(flipInner, { rotateY: 0, });
        }

        tl.to(flipInner, { rotateY: (reverse ? 0 : -180), ease: "power4.inOut"}, 0);

        return tl;
    }

    function anim__toggleGrow(ele) {
        var tl = gsap.timeline({
                onStart: function(){
                    setTimeout(function() {
                        $('body').addClass('nav--active')
                    }, 400);
                },
            }),
            scale = ele.find('.nav__scale');

        gsap.set(ele, { display: 'none' })
        gsap.set(scale, { scale: 0, y: 0, x: 29, yPercent: -50, xPercent: -50 })
        
        // set width of toggle circle
        $(window).on('load resize', function () {
            var value = (window.innerHeight > window.innerWidth ? window.innerHeight : window.innerWidth)*2.5;
                scale.css( {'width': value, 'height': value} );
        });

        tl
            .set(ele, { display: 'block'})
            .to(scale, 1,{scale: 1, ease: "power4.inOut",})
        
        return tl;
    }

    function anim__hideBack(ele) {
        var back = ele.find('.header__back');
        var tl = gsap.timeline({});

        tl.to(back, {autoAlpha: 0});

        return tl;
    }

    function anim__showBack(ele) {
        var back = ele.find('.header__back');
        var tl = gsap.timeline({});

        tl.to(back, {autoAlpha: 1});

        return tl;
    }

    function anim__showContainer(ele) {
        var inner = ele.find('.nav__inner');
        var tl = gsap.timeline({});

        gsap.set(inner, { opacity: 0 })
        
        tl.to(inner, { opacity: 1})
        
        return tl;
    }

    function closeMenu() {
        if(checkbox.length) {
            checkbox.prop('checked', false);
            if(checkbox[0].animation.currentLabel() == 'Open') {
                checkbox[0].animation.tweenFromTo('Open', 'Close')
            }
        }
    }

    // barba hook
    if(window.barba !== undefined) {
        barba.hooks.afterLeave((data) => {
            closeMenu()
        });
    }

    // close menu after click
    nav.on('click', 'a', function() {
        if(window.location.pathname == $(this).attr('href')) {
            closeMenu()
        }
    });

})(jQuery);