(function ($) {
    
    function init__vip() {
        const component = window.helpers.component('vip');
        
        component.$.each(function(){
            const 
                $this = $(this),
                bg = $this.find(component.prefix + '__bg');

            $this.mousemove(function(event) {
                window.animation.parallax(event, bg, false, 30);         
            });
        });
    }

    init__vip();

    // run function after barba enter
    if(window.barba !== undefined) {
        barba.hooks.enter((data) => {
            init__vip();
        });
    }

})(jQuery);